.tg-header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999999;
  width: 100%;
  height: 80px;
  background: $color_white;
  @include box-shadow(0px -1px 10px rgba(0, 0, 0, 0.2));
  @include transition(all 0.3s ease 0s);

  @include breakpoint(small only) {
    height: 50px;
  }

  .tg-header-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 900px) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    .tg-logo {
      padding-left: 15px;
      margin-top: -16px;
      text-align: center;

      @media (max-width: 900px) {
        display: none;
      }

      a {
        display: block;
        line-height: 1;
      }
    }

    .tg-moblie-logo {
      display: none;
      padding-left: 15px;

      @media (max-width: 900px) {
        display: block;
        flex: 0 0 130px;
        max-width: 130px;
      }

      a {
        display: block;
        line-height: 1;
      }
    }

    .tg-menu-container {
      width: 100%;
      align-items: center;
      justify-content: space-between;
      display: flex;

      @media (max-width: 900px) {
        display: none;
      }
    }

    .tg-menus-desktop {
      ul {
        margin: 0;
        display: flex;

        li {
          display: block;
          text-decoration: none;
          padding: 0 40px;

          @media (max-width: 1200px) {
            padding: 0 20px;
          }

          a {
            font-family: $prata;
            color: $color_menu;
            font-size: 24px;
            display: inline-block;
            position: relative;
            @include transition(all 0.3s ease 0s);

            @include breakpoint(small only) {
              font-size: 17px;
            }

            &:before {
              content: "";
              position: absolute;
              bottom: 0;
              left: 0;
              height: 2px;
              background: $color_orange;
              width: 0;
              @include transition(all 0.3s ease 0s);
            }

            // &:hover {
            //   color: $color_orange;

            //   &:before {
            //     width: 100%;
            //   }
            // }
          }
        }
      }
    }

    .get-in-touch {
      margin-right: 20px;
      margin-top: 17px;
    }

    .tg-header-rightelemets {
      display: none;

      @media (max-width: 900px) {
        flex: 0 0 340px;
        max-width: 340px;
        display: flex;
      }

      @include breakpoint(small only) {
        flex: 0 0 50px;
        max-width: 50px;
      }

      .button {
        height: 80px;
        margin: 0;
        position: relative;

        @include breakpoint(small only) {
          height: 50px;
        }

        &.btn-quote {
          width: 260px;
          font-size: 18px;
          font-weight: 600;
          line-height: 2.5;

          @include breakpoint(small only) {
            display: none;
          }

          i {
            font-size: 34px;
            line-height: 1;
            vertical-align: middle;

            @include breakpoint(small only) {
              font-size: 18px;
            }
          }
        }

        &.btn-menu {
          width: 80px;
          background: $color_blue;

          @include breakpoint(small only) {
            width: 50px;
          }

          span {
            position: absolute;
            left: auto;
            right: 14px;
            height: 2px;
            background: $color_white;
            @include transition(all 0.3s ease 0s);

            @include breakpoint(small only) {
              right: 5px;
            }

            &:nth-child(1) {
              width: 50px;
              top: 26px;

              @include breakpoint(small only) {
                width: 38px;
                top: 14px;
              }
            }

            &:nth-child(2) {
              width: 38px;
              top: 38px;

              @include breakpoint(small only) {
                width: 30px;
                top: 23px;
              }
            }

            &:nth-child(3) {
              width: 24px;
              top: 50px;

              @include breakpoint(small only) {
                width: 21px;
                top: 32px;
              }
            }
          }

          &:hover {
            span {
              &:nth-child(2),
              &:nth-child(3) {
                width: 50px;

                @include breakpoint(small only) {
                  width: 38px;
                }
              }
            }
          }
        }
      }
    }
  }

  &.nav-small {
    top: -120px;
    left: 0;
    position: absolute;
    height: 80px;

    @include breakpoint(small only) {
      height: 50px;
    }
  }

  &.sticky_animate {
    top: 0;
    position: fixed;

    @include breakpoint(small only) {
      position: absolute;
    }
  }
}

.tg-navigation {
  position: fixed;
  top: -100vh;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background: $color_black;
  z-index: 99999999;
  @include transition(all 0.3s ease 0s);
  opacity: 0;
  visibility: hidden;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;

  @include breakpoint(small only) {
    padding: 25px 15px;
  }

  &.tg-nav-open {
    opacity: 1;
    visibility: visible;
    top: 0;
  }

  .btn-menu-close {
    position: absolute;
    right: 120px;
    top: 50px;
    width: 50px;
    height: 50px;
    cursor: pointer;
    text-align: center;

    @include breakpoint(small only) {
      right: 15px;
      top: 15px;
      width: 35px;
      height: 35px;
    }

    .closetext {
      margin: 0;
      text-transform: uppercase;
      font-size: 12px;
      letter-spacing: 2px;
      color: $color_white;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      @include transition(all 0.3s ease 0s);
      opacity: 0;
      font-style: normal;
    }

    span {
      position: absolute;
      width: 50px;
      height: 3px;
      background: $color_white;
      top: 24px;
      left: 0;
      right: 0;
      margin: 0 auto;
      @include transition(all 0.3s ease 0s);

      @include breakpoint(small only) {
        width: 35px;
      }

      &:nth-child(1) {
        @include transform(rotate(#{45}deg));
      }

      &:nth-child(2) {
        @include transform(rotate(#{-45}deg));
      }
    }

    &:hover {
      .closetext {
        bottom: -21px;
        opacity: 1;
      }

      span {
        &:nth-child(1) {
          @include transform(rotate(#{-45}deg));
        }

        &:nth-child(2) {
          @include transform(rotate(#{45}deg));
        }
      }
    }
  }

  .container {
    max-width: 980px;
    margin: 0 auto;
    height: 100%;

    .row {
      max-width: 100%;
    }
  }

  .tg-menus {
    @include breakpoint(small only) {
      margin-bottom: 25px;
    }

    ul {
      margin: 0;

      li {
        display: block;

        &:not(:last-child) {
          margin-bottom: 20px;
        }

        a {
          font-family: $prata;
          color: $color_white;
          font-size: 38px;
          display: inline-block;
          position: relative;
          @include transition(all 0.3s ease 0s);

          @include breakpoint(small only) {
            font-size: 17px;
          }

          &:before {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            height: 2px;
            background: $color_orange;
            width: 0;
            @include transition(all 0.3s ease 0s);
          }

          // &:hover {
          //   color: $color_orange;

          //   &:before {
          //     width: 100%;
          //   }
          // }
        }
      }
    }
  }

  .tg-quick-link {
    &:not(:last-child) {
      margin-bottom: 30px;
    }

    h6 {
      color: $color_white;
    }

    p {
      color: $color_white;
    }

    .tg-navarrow {
      color: $color_white;

      &:before,
      &:after {
        background: $color_white;
      }

      span {
        display: block;

        &:before,
        &:after {
          background: $color_white;
        }
      }
    }

    .tg-emaillink {
      color: $color_white;
    }

    &.tg-menu-social {
      ul {
        margin: 0;

        li {
          display: inline-block;

          &:not(:last-child) {
            margin-right: 10px;
          }

          a {
            width: 40px;
            height: 40px;
            border: 1px solid transparent;
            text-align: center;
            display: block;
            text-align: center;
            line-height: 36px;
            font-size: 15px;
            position: relative;
            color: $color_white;

            &:before,
            &:after {
              content: "";
              width: 0;
              height: 1px;
              position: absolute;
              transition: all 0.2s linear;
              background: $color_white;
              transition-delay: 0.2s;
            }

            &:before {
              right: 0;
              top: 0;
            }

            &:after {
              left: 0;
              bottom: 0;
            }

            span {
              display: block;

              &:before,
              &:after {
                content: "";
                width: 1px;
                height: 0;
                position: absolute;
                transition: all 0.2s linear;
                background: $color_white;
                transition-delay: 0s;
              }

              &:before {
                left: 0;
                top: 0;
              }

              &:after {
                right: 0;
                bottom: 0;
              }
            }

            &:hover {
              &:before,
              &:after {
                width: 100%;
                transition-delay: 0s;
              }

              span {
                &:before,
                &:after {
                  height: 100%;
                  transition-delay: 0.2s;
                }
              }
            }
          }
        }
      }
    }
  }
}

.tg-active-link {
  color: $color_orange !important;
  border-bottom: 2px solid $color_orange;
}

.dropbtn {
  font-family: $prata;
  color: $color_menu;
  font-size: 24px;
  display: inline-block;
  position: relative;
  @include transition(all 0.3s ease 0s);
  cursor: pointer;

  @media (max-width: 900px) {
    color: $color_white;
  }
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #ffff;
  min-width: 365px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;

  @media (max-width: 430px) {
    min-width: 265px;
  }
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black !important;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  font-size: 17px !important;
  width: 100%;

  @media (max-width: 430px) {
    font-size: 13px !important;
  }
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #f1f1f1;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

.tg-vision {
  font-size: 18px;
  margin-top: 3px;
}
