.tg-login {
  display: flex;
  height: 100vh;
  max-width: 100%;
  align-items: center;
  justify-content: center;

  &__container {
    background: #ffffff;
    border: 1px solid #707070;
    padding: 50px;
    width: 45%;
  }

  &__header {
    display: flex;
    flex-direction: column;
    text-align: center;

    &-title {
      color: $color_title;
      line-height: 36px;
      font-size: 25px;
      font-weight: 700;
    }

    &-min-title {
      font-weight: 600;
      font-size: 20px;
      color: $color_title;
      margin-top: 20px;
    }

    &-sub-title {
      font-weight: 600;
      font-size: 20px;
      color: $color_title;
      margin-top: 20px;
      margin-bottom: 50px;
    }
  }
  .form-group {
    label {
      color: $color_title;
      font-weight: 600;
    }
    .form-control {
      border: 2px solid #3e3e3e;
    }
  }

  &__desc {
    text-align: center;
    color: $color_title;
    font-weight: 600;
  }

  &__link {
    text-align: right;

    a {
      color: $color_title;
      font-weight: 600;
    }
  }

  &__action {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
  }
}
