// FONTS
$open_sans: 'Open Sans',
sans-serif;
$prata: 'Prata',
serif;

// COLOR CODE
$color_orange: #FF8F00;
$color_blue  : #010030;
$color_green : #5A8F29;
$color_white : #FFFFFF;
$color_black : #2B2B2B;
$color_grey  : #8C8C8C;
$color_menu  : #141414;
$color_dark  :#000000;
$color_title  :#3D3D3D;
