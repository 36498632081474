.tg-backtotop {
  width      : 40px;
  height     : 40px;
  border     : 1px solid transparent;
  text-align : center;
  display    : block;
  text-align : center;
  line-height: 36px;
  font-size  : 28px;
  position   : fixed;
  right      : 25px;
  bottom     : -30px;
  opacity    : 0;
  visibility : hidden;
  cursor     : pointer;
  @include transition(all 0.3s ease 0s);

  &.show {
    opacity   : 1;
    visibility: visible;
    bottom    : 60px;
  }

  &:before,
  &:after {
    content         : "";
    width           : 0;
    height          : 1px;
    position        : absolute;
    transition      : all 0.2s linear;
    background      : $color_black;
    transition-delay: 0.2s;
  }

  &:before {
    right: 0;
    top  : 0;
  }

  &:after {
    left  : 0;
    bottom: 0;
  }

  span {
    display: block;

    &:before,
    &:after {
      content         : "";
      width           : 1px;
      height          : 0;
      position        : absolute;
      transition      : all 0.2s linear;
      background      : $color_black;
      transition-delay: 0s;
    }

    &:before {
      left: 0;
      top : 0;
    }

    &:after {
      right : 0;
      bottom: 0;
    }
  }

  &:hover {

    &:before,
    &:after {
      width           : 100%;
      transition-delay: 0s;
    }

    span {

      &:before,
      &:after {
        height          : 100%;
        transition-delay: 0.2s;
      }
    }
  }
}

.tg-subscribe-form {
  display: flex;
  width  : 100%;

  .form-group {
    margin-bottom: 0;
    position     : relative;
    width        : 100%;

    .form-control {
      color        : #fff;
      outline      : none;
      border       : none;
      background   : none;
      padding      : 0;
      border-bottom: 1px solid #fff;
    }
  }

  button {
    position : absolute;
    font-size: 25px;
    z-index  : 9999;
    right    : 0;
    color    : #fff;
    top      : 10px;
    cursor   : pointer;
  }
}