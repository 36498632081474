$app-dashboard-top-nav-color: #2c3840;
$app-dashboard-top-nav-height: 55px;

.app-dashboard {
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.app-dashboard-body {
    flex: 1 1 auto;
    display: flex;
}

.app-dashboard-top-nav-bar {
    display: flex;
    align-items: center;
    justify-content: center;
    background: $app-dashboard-top-nav-color;
    height: $app-dashboard-top-nav-height;
    width: 100%;
    flex: 0 0 $app-dashboard-top-nav-height;
    .menu-icon {
        vertical-align: text-bottom;
    }
}

.app-dashboard-logo {
    color: $white;
    text-transform: uppercase;
    font-weight: bold;
}

.app-dashboard-search-bar-container {
    position: relative;
}

.app-dashboard-search {
    background: lighten($app-dashboard-top-nav-color, 10%);
    border: 0;
    margin-bottom: 0;
    color: $white;

    &:active,
    &:focus {
        background: darken($app-dashboard-top-nav-color, 5%);
    }
}

.app-dashboard-search-icon {
    position: absolute;
    color: $white;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
}

.app-dashboard-top-bar-actions {
    align-items: center;
    display: flex;

    button {
        margin-bottom: 0;
        margin-right: 2rem;

        &.hollow {
            border-color: $white;
            color: $white;

            &:hover {
                background: $white;
                color: $primary-color;
            }
        }
    }

    .fa-info-circle {
        color: $white;
        font-size: 1.5rem;
    }
}

.app-dashboard-sidebar {
    background-color: $white;
    height: 100%;
    overflow-x: visible;
    overflow-y: auto;
    z-index: 1;
    transition: all $offcanvas-transition-length $offcanvas-transition-timing;

    .app-dashboard-open-sidebar,
    .app-dashboard-close-sidebar {
        align-items: baseline;
        display: flex;
        justify-content: space-between;
        padding: 2rem 1rem;
    }

    .app-dashboard-sidebar-close-button {
        font-size: 14px;
    }

    .app-dashboard-sidebar-inner {
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        .menu > li > a {
            align-items: center;
            flex-direction: column;
            display: flex;
            padding: 20px;

            i {
                font-size: 20px;
                margin-bottom: 5px;
            }

        }
    }
    .fa.large {
        font-size: rem-calc(24);
        width: 40px;
    }
}

.reveal-for-medium {
    .app-dashboard-open-sidebar {
        display: none;
    }
}

.app-dashboard-sidebar-footer {
    background: rgba(42, 57, 79, 0.8);
    bottom: 0;
    left: 0;
    padding: 1rem;
    position: absolute;
    width: 100%;
}

.app-dashboard-open-sidebar {
    text-align: center;
}

.position-left.reveal-for-medium {
    width: 140px;
}

.app-dashboard-body-content {
    transition: all $offcanvas-transition-length $offcanvas-transition-timing;
    overflow-y: auto;
    flex: 1 1 0;
    padding: 20px;
    background-color: $white;
}

@mixin shrunk-sidebar() {
    .app-dashboard-close-sidebar,
    .app-dashboard-sidebar-text {
        display: none;
    }

    .app-dashboard-open-sidebar {
        display: block;
    }

    .app-dashboard-sidebar {
        width: 80px;
        .fa.large {
            width: auto;
        }
    }

    .off-canvas-content {
        margin-left: 80px;
        width: calc(100% - 80px);
    }

    .navigation {
        margin-top: 2rem;
        text-align: center;
    }
    .menu.vertical > li > a {
        justify-content: center;
    }

    .menu {
        li {
            &::after {
                display: none;
                opacity: 0;
                transition: opacity 0.5s ease-in-out;
            }

            a {
                
                span {
                    display: none;
                    opacity: 0;
                    transition: opacity 0.5s ease-in-out;
                }
            }
        }
    }
}

// mini sidebar on medium/tablet size
@include breakpoint(medium only) {
    .app-dashboard.shrink-medium {
        @include shrunk-sidebar();
    }
}
@include breakpoint(large) {
    .app-dashboard.shrink-large {
        @include shrunk-sidebar();
    }
}

.position-left.reveal-for-medium ~ .off-canvas-content {
    margin-left: 135px;
}