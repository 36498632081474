// PRE LOADER
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  /* change if the mask should have another color then white */
  z-index: 9999;
  /* makes sure it stays on top */
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  text-align: center;

  img {
    margin: 0 auto;
    width: 350px;
  }
}

// BANNER CSS
.tg-banner-wrap {
  background: #f5f5f5;
  position: relative;
  height: 100vh;
  overflow: hidden;

  @include breakpoint(small only) {
    height: 50vh;
  }

  .tg-banner-watermark {
    position: absolute;
    display: flex;
    display: -ms-flexbox;
    align-items: center;
    height: 100%;
    width: 100%;
    text-align: center;
    @include transition(all 1s ease 1s);
    z-index: 1;


    span {
      font-family: "Roboto", sans-serif;
      font-size: 18vw;
      display: block;
      text-align: center;
      flex: 1;
      -ms-flex: 1;
      color: rgba(0, 0, 0, 0.02);
    }
  }

  .tg-banner-content {
    position: relative;
    z-index: 2;
    display: flex;
    display: -ms-flexbox;
    align-items: center;
    height: 100%;
    color: $color_white;

    .tg-banner-caption {
      flex: 1;
      -ms-flex: 1;
      text-align: center;

      p {
        margin: 0;
        font-size: 24px;
        font-weight: 400;

        @include breakpoint(small only) {
          font-size: 17px;
        }
      }
    }

    .tg-seach-form {
      margin: auto;

      .form-group {
        position: relative;
        margin-top: 30px;
      }

      .form-control {
        width: 100%;
        height: 64px;

        @media (max-width:700px) {
          width: 70%;
        }
        
        &::placeholder {
          color: #000000;
          font-size: 20px;
        }
      }

      .icon {
        position: absolute;
        top: 15px;
        right: 12px;
        color: #000000;
        font-size: 32px;
        cursor: pointer;
      }
    }

    .tg-banner-shortlink {
      position: absolute;
      left: 0;
      bottom: 60px;
      padding-left: 100px;

      @include breakpoint(small only) {
        display: none;
      }

      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 10px;
        width: 90px;
        height: 1px;
        background: $color_black;
      }

      h6 {
        margin-bottom: 10px;
        color: $color_dark;
      }

      p {
        width: 230px;
      }
    }

    .tg-scrolldown {
      @include transform(rotate(#{-90}deg));
      position: absolute;
      right: 0px;
      bottom: 120px;
      z-index: 2;

      @include breakpoint(small only) {
        right: 0px;
        bottom: 25px;
        z-index: 2;
        left: 0;
        width: 40px;
        height: 40px;
        margin: 0 auto;
        text-align: center;
        padding: 6px 0;
      }

      a {
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: 1px;
        color: $color_white;

        span {
          @include breakpoint(small only) {
            display: none;
          }
        }

        i {
          vertical-align: middle;
          font-size: 28px;
        }
      }
    }
  }
}

// WHAT IS OUR DIFFERENCE
.pv-whatifference-wrap {
  &:focus {
    outline: none !important;
  }

  .tg-title {
    text-align: center;
    width: 75%;
    max-width: 100%;
    margin: 0 auto;
    color: $color_dark;

    @include breakpoint(small only) {
      width: 100%;
      padding: 0 15px;
    }

    p {
      color: $color_dark;
      font-size: 16px;
      line-height: 26px;
    }
  }

  .tg-quote-wrap {
    position: relative;

    .tg-overlay {
      background-color: unset;
    }

    @include breakpoint(small only) {
      width: 100%;
      height: auto;
      margin: 30px auto 0;
    }

    .tg-quoteimage {
      position: absolute;
      background: #999;
      width: 380px;
      height: 430px;
      z-index: 1;
      left: 0;
      top: 0;
      overflow: hidden;

      @include breakpoint(small only) {
        position: relative;
        width: 100%;
      }

      img {
        width: 100%;
      }
    }

    .tg-quotetext {
      position: absolute;
      width: 622px;
      height: 100%;
      z-index: 2;
      top: 0;
      display: flex;
      display: -ms-flexbox;
      align-items: center;
      padding: 0 45px;

      @include breakpoint(small only) {
        width: 100%;
        top: 0;
      }

      h4 {
        margin: 0;
        font-size: 26px;
      }

      .tg-textnavarrow {
        position: absolute;
        bottom: 30px;
        left: 45px;
      }
    }
  }
}
.tg-quote-wrap-img {
  @media (max-width: 550px) {
    min-height: 372px;
    object-fit: cover;
  }
}
.tg-technology-list {
  padding: 0 15px;
  max-width: 100%;
  margin: 0 auto;

  ul {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0;

    li {
      display: inline-block;
      border: 1px solid transparent;
      -ms-flex: 0 0 24.333333%;
      flex: 0 0 24.333333%;
      max-width: 24.333333%;
      margin: 0 auto;
      padding: 20px 80px;

      @include breakpoint(small only) {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
        padding: 20px;
      }

      img {
        width: 100%;
        -webkit-filter: grayscale(0);
        /* Safari 6.0 - 9.0 */
        filter: grayscale(0);
        @include transition(all 0.3s ease 0s);
        @include transform(scale(0.8, 0.8));
      }

      &:hover {
        img {
          -webkit-filter: grayscale(0);
          /* Safari 6.0 - 9.0 */
          filter: grayscale(0);
          @include transform(scale(1, 1));
        }
      }
    }
  }
}

.tg-portfolio-wrap {
  padding: 0 30px;

  @include breakpoint(small only) {
    padding: 0 15px;
  }

  .tg-viewall-link {
    position: relative;
    margin-top: 50px;

    &:before {
      content: "";
      position: absolute;
      top: 12px;
      left: 0;
      width: calc(100% - 180px);
      height: 2px;
      background: $color_black;
    }

    a {
      font-size: 15px;
      font-weight: 600;
      color: $color_black;
      font-weight: 600;
      float: right;

      i {
        font-size: 28px;
        vertical-align: middle;
      }
    }
  }

  .tg-portfolio-row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0 -15px;

    .tg-portfolio-left {
      flex: 0 0 25%;
      -ms-flex: 0 0 25%;
      max-width: 25%;
      margin: 0 15px;
      background: $color_blue;
      height: 640px;
      align-items: center;
      display: -ms-flexbox;
      display: flex;

      @include breakpoint(small only) {
        display: none;
      }

      .tg-portfolio-left-content {
        position: relative;
        flex: 1;

        .tg-title {
          text-align: left;
          padding: 65px 65px 0;

          h3 {
            color: $color_white;
            font-size: 38px;
            text-transform: uppercase;
          }
        }

        .tg-portfolio-link {
          padding: 0;
          margin: 0;

          li {
            display: block;

            a {
              display: block;
              color: $color_white;
              font-size: 17px;
              letter-spacing: 1px;
              padding: 25px 65px;
              font-weight: 400;
              position: relative;
              @include transition(all 0.3s ease 0s);

              &:before {
                content: "";
                position: absolute;
                width: 0;
                height: 2px;
                background: $color_orange;
                left: 0;
                top: 36px;
                @include transition(all 0.3s ease 0s);
              }

              &:hover {
                color: $color_orange;

                &:before {
                  width: 50px;
                }
              }
            }

            &.tg-active {
              a {
                color: $color_orange;
                font-weight: 600;

                &:before {
                  width: 50px;
                }
              }
            }
          }
        }
      }
    }

    .tg-portfolio-right {
      flex: 1 0 0;
      -ms-flex: 1 0 0;
      margin: 0 15px;

      .tg-portfolio-list {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin: 0;

        li {
          display: block;
          -ms-flex: 0 0 25%;
          flex: 0 0 25%;
          max-width: 25%;
          height: 320px;
          position: relative;

          @include breakpoint(small only) {
            display: block;
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;
            height: 320px;

            &:not(:last-child) {
              margin-bottom: 15px;
            }
          }

          .tg-folio-img {
            position: relative;
            overflow: hidden;
            width: 100%;
            height: 100%;

            img {
              object-fit: cover;
              width: 100%;
              height: 100%;
            }
          }

          .tg-folio-content {
            position: absolute;
            z-index: 2;
            width: 100%;
            height: 100%;
            background: rgba(1, 0, 48, 0.7);
            top: 0;
            left: 0;
            padding: 45px 35px;
            opacity: 0;
            @include transition(all 0.3s ease 0s);
            @include transform(scale(1.1, 1.1));

            &:before {
              content: "";
              position: absolute;
              left: 0;
              right: 0;
              top: 0;
              bottom: 0;
              margin: 0 auto;
              width: 100%;
              height: 100%;
              border: 2px solid $color_white;
              @include transform(scale(1, 1));
              @include transition(all 0.3s ease 0.2s);
              opacity: 0;
            }

            h6 {
              color: $color_white;
              margin-bottom: 5px;
            }

            .tg-folio-subtitle {
              display: block;
              color: $color_white;
              font-size: 14px;
            }

            .tg-navarrow {
              position: absolute;
              bottom: 35px;
              right: 30px;
              color: $color_white;

              &:before,
              &:after {
                background: $color_white;
              }

              span {
                display: block;

                &:before,
                &:after {
                  background: $color_white;
                }
              }
            }
          }

          &:hover {
            .tg-folio-content {
              opacity: 1;
              @include transform(scale(1, 1));

              &:before {
                @include transform(scale(0.9, 0.9));
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
}

.tg-testimonial-wrap {
  padding: 0 15px;

  .tg-testi-box {
    position: relative;
    width: 960px;
    max-width: 100%;
    border: 2px solid $color_black;
    padding: 50px 35px;
    margin: 0 auto;
    text-align: center;

    .tg-testi-title {
      position: absolute;
      text-align: center;
      left: 0;
      right: 0;
      top: -13px;

      h6 {
        display: inline-block;
        background: $color_white;
        padding: 0 30px;
        color: $color_orange;
      }
    }

    .tg-quoteicon {
      color: $color_orange;
      font-size: 60px;
    }
  }
}

.tg-home-contact-wrap {
  padding: 100px 0;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  margin-top: 180px;

  @include breakpoint(small only) {
    margin-top: 60px;
    padding: 50px 0 0;

    .medium-5 {
      order: 2;
      padding: 0;
    }

    .medium-7 {
      order: 1;
      padding: 0;
    }
  }

  .tg-title-2 {
    text-align: left;
    padding: 0;

    @include breakpoint(small only) {
      padding: 0 25px;
    }

    .tg-subtitle {
      color: $color_white;
    }

    h3 {
      color: $color_white;
    }
  }

  .tg-locations {
    ul {
      padding: 0;
      margin: 0;
      display: flex;
      display: -ms-flexbox;
      flex-wrap: wrap;
      -ms-flex-wrap: wrap;

      @include breakpoint(small only) {
        display: block;
        padding: 0 25px;
      }

      li {
        display: block;
        flex: 1;
        -ms-flex: 1;
        color: $color_white;

        @include breakpoint(small only) {
          &:not(:last-child) {
            margin-bottom: 25px;
          }
        }

        a {
          color: #fff;
        }
        .tg-place {
          text-transform: uppercase;
          font-size: 15px;
          display: block;
        }

        .tg-number {
          font-weight: 600;
          font-size: 15px;
          display: block;
        }
      }
    }

    .media-object {
      align-items: center;
      margin: 0;

      .thumbnail {
        width: 50px;
        height: 50px;
        @include border-radius(100%);
        color: $color_white;
        background: $color_orange;
        text-align: center;
        line-height: 48px;
        border: none;
        font-size: 24px;
      }
    }
  }

  .tg-contactform {
    background: $color_white;
    padding: 35px;
    margin: -180px 0;
    @include box-shadow(0px 0px 15px rgba(0, 0, 0, 0.2));

    @include breakpoint(small only) {
      margin: 50px 0 0px 0;
      box-shadow: none;
      padding: 30px 15px;
    }

    .form-group {
      label {
        font-weight: 600;
        margin-bottom: 5px;
      }

      textarea.form-control {
        resize: auto;
        height: 100px;
      }

      &-radio {
        display: flex;

        .radio-inline {
          margin-right: 10px;
        }
      }
      .button {
        display: block;
        width: 100%;
        font-size: 15px;
        font-weight: 600;
        letter-spacing: 1px;
        margin: 0;
      }
    }
  }
}

.tg-nextpagelink-wrap {
  padding: 50px 15px;
  text-align: center;

  .tg-next {
    display: block;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .tg-textnavarrow {
    font-size: 17px;
    font-weight: 700;
  }
}

.containerbox {
  overflow: hidden;
  position: relative;
  height: 500px;
  cursor: move;
}

.panoramixImg {
  width: auto;
  max-width: none;
  height: 100%;
  position: absolute;
  margin: auto;
  border: 1px solid #ccc;
}

.pano {
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

.pano .controls {
  position: relative;
  font-size: 1.8em;
}

.pano .controls a {
  position: absolute;
  display: block;
  text-decoration: none;
  color: #eee;
  font-size: 1.6em;
  width: 40px;
  height: 40px;
  padding: 0 0;
  display: none;
}

.pano .controls a.left {
  left: 10px;
}

.pano .controls a.right {
  right: 10px;
  text-align: right;
}

.moving .pano .controls a {
  opacity: 0.4;
  color: #eee;
  z-index: 80;
}

.tg-whatistg-wrap {
  .tg-title-2 {
    width: 1000px;
  }

  .row {
    h6 {
      letter-spacing: 1px;
    }

    p {
      margin-bottom: 60px;
    }
  }
}

.tg-values-wrap {
  background: #f4f4f4;
  padding: 100px 0;

  .tg-title-2 {
    width: 800px;
  }

  ul {
    margin: 0;
    padding: 0;
    display: flex;
    display: -ms-flexbox;
    flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    width: 100%;

    li {
      display: block;
      flex: 1;
      text-align: center;

      .tg-count {
        display: block;
        font-weight: 700;
        font-size: 24px;
      }

      .tg-name {
        display: block;
        font-weight: 700;
        font-size: 17px;
      }
    }
  }
}

.tg-culture-wrap {
  .tg-empimg-top {
    height: 380px;
    overflow: hidden;

    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }

  .tg-empimg-bottom {
    height: 580px;
    overflow: hidden;

    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }

    &.tg-emptext {
      padding-top: 150px;

      .tg-title-2 {
        padding: 0;

        h3 {
          font-size: 34px;
        }
      }
    }
  }

  .tg-viewall-link {
    position: relative;
    margin-top: 50px;
    width: 100%;

    &:before {
      content: "";
      position: absolute;
      top: 12px;
      left: 0;
      width: calc(100% - 180px);
      height: 2px;
      background: $color_black;
    }

    a {
      font-size: 15px;
      font-weight: 600;
      color: $color_black;
      font-weight: 600;
      float: right;

      i {
        font-size: 28px;
        vertical-align: middle;
      }
    }
  }
}

.tg-team-wrap {
  .tg-teambox {
    margin-bottom: 50px;

    .tg-teamimg {
      img {
        width: 100%;
      }
    }

    .tg-caption {
      margin-top: 15px;
      text-align: center;

      .tg-post {
        display: block;
        font-size: 14px;
        margin-bottom: 5px;
      }

      .tg-name {
        display: block;
        font-size: 17px;
        font-weight: 700;
      }
    }
  }
}

.tg-careerctc-wrap {
  .tg-careerctc-content {
    width: 100%;
    background: #f5f5f5;
    padding: 100px 150px;

    .tg-title-2 {
      width: 100%;
    }

    .button {
      width: 200px;
      font-weight: 700;
      margin-top: 25px;
      margin-bottom: 0;
    }
  }
}

.tg-hidden-captcha {
  margin-top: 16px;
}

.tg-locationmap-wrap {
  .tg-map {
    img {
      width: 100%;
    }
  }

  .tg-locationname {
    &:not(:last-child) {
      margin-bottom: 40px;
    }

    h6 {
      letter-spacing: 1px;
    }

    address {
      font-style: normal;
    }
  }
}

.tg-careerlist-wrap {
  .tg-container {
    text-align: center;
    padding: 0 15px;
    margin: 0 auto;
    color: #000000;

    p {
      font-size: 18px;
      font-weight: 600;
    }
  }

  .tg-title-2 {
    width: 755px;
  }

  .mt-30px {
    margin-top: 30px;

    @media (max-width: 767px) {
    }
  }

  .tg-careerbox-second-item {
   
    @media (max-width: 767px) {
      margin-top: 30px;
    }
  }

  .tabs-content {
    border: 0;
  }

  .tg-careerbox {
    @include box-shadow(0 0 8px rgba(0, 0, 0, 0.15));
    padding: 30px 25px 25px;
    min-height: 185px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .tg-careerbox-header {
      display: flex;
      color: #000000;
      justify-content: space-between;
    }

    h6 {
      letter-spacing: 0;
      font-size: 22px;
    }

    ul {
      margin: 0;
      padding: 0;

      li {
        display: inline-block;
        list-style: none;
        position: relative;
        font-size: 14px;

        &:not(:last-child) {
          margin-right: 30px;
        }

        // &:before {
        //   content: "";
        //   position: absolute;
        //   width: 1px;
        //   height: 14px;
        //   background: #ccc;
        //   right: -15px;
        //   top: 5px;
        // }

        &:last-child {
          &:before {
            display: none;
          }
        }
      }
    }

    .tg-caption {
      margin-top: 15px;

      p {
        font-size: 14px;
      }
    }
  }
}

.tg-whyus-wrap {
  padding: 120px 0;
  background: #010030;

  .tg-subtitle {
    color: $color_white;
    h3 {
      color: $color_white;
    }
  }

  .tg-title-2 h3 {
    color: $color_white;
  }

  .tg-offerbox {
    position: relative;
    margin-bottom: 30px;
    height: 337px;
    background: #fff;

    .tg-offerimg {
      img {
        width: 100%;
      }
    }

    .tg-caption {
      position: absolute;
      top: 27%;
      left: 0;
      width: 100%;
      z-index: 2;
      padding: 20px;

      h5 {
        color: $color_black;
        font-family: $prata;
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 30px;
      }

      p {
        color: $color_black;
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
      }
    }
  }
}

.tg-carrerbrief {
  width: 800px;
  max-width: 100%;
  padding: 0 15px;
  margin: 60px auto 0;
  position: relative;
  font-size: 16px;

  p {
    line-height: 26px;
    text-align: justify;
  }

  .tg-jobtype {
    margin-top: 25px;

    li {
      margin-bottom: 15px;
      display: flex;
      strong {
        display: inline-block;
        min-width: 160px;
      }
    }
  }

  h6 {
    letter-spacing: 0;
    font-size: 22px;
    margin-bottom: 15px;
  }

  .tg-requirement {
    margin: 10px 0 0;
    padding: 0;

    li {
      display: inline-block;
      background: #f5f5f5;
      padding: 5px 15px;
      margin-bottom: 10px;
      font-weight: 600;
      @include border-radius(3px);

      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }

  .divider {
    position: relative;
    margin: 50px 0;
    height: 3px;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 200px;
      height: 3px;
      background: $color_blue;
    }
  }
}

.tg-back {
  position: absolute;
  top: 100px;
  left: 25px;
  z-index: 9;
  color: $color_blue;
}

.tg-careerform-wrap {
  background: #f5f5f5;
  padding: 120px 0;

  .tg-title-2 {
    width: 100%;
    text-align: left;
  }

  .tg-formcontainer {
    width: 800px;
    max-width: 100%;
    margin: 0 auto;
  }

  form {
    width: 100%;

    .form-group {
      margin-bottom: 15px;
    }

    textarea.form-control {
      height: 200px;
    }

    .tg-uploadbox {
      color: $color_white;
      background: #010030;
      padding: 20px 0 20px 20px;
      box-shadow: 0px 4px 4px rgba(255, 255, 255, 0.25);
      font-weight: 600;
      font-size: 17px;
      margin-top: 5px;
      cursor: pointer;

      i {
        display: inline-block;
        margin-right: 8px;
      }

      span {
        display: inline-block;
      }
    }

    .tg-uploadname {
      background: rgba(0, 0, 0, 0.1);
      padding: 10px 15px;
      display: inline-block;
      margin-top: 15px;
      font-weight: 600;
      color: $color_blue;

      .tg-remove {
        display: inline-block;
        margin-left: 10px;
        width: 25px;
        height: 25px;
        background: rgba(0, 0, 0, 0.3);
        color: $color_white;
        text-align: center;
        vertical-align: middle;
        line-height: 25px;
        border-radius: 5px;
        cursor: pointer;
        padding-right: 1px;
      }
    }

    .button {
      margin-top: 15px;
      width: 250px;
      font-weight: 700;
      margin-bottom: 0;
    }
  }
}

.tg-footer {
  padding: 30px;
  background: #000;

  &__list {
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 756px) {
      flex-direction: column;
    }

    &-brand {
      color: #fff;
      text-align: center;

      &-text {
        font-size: 18px;
      }

      @media (max-width: 756px) {
        width: 100%;
      }
    }

    h5 {
      color: #fff;

      @media (max-width: 756px) {
        margin-top: 20px;
      }
    }

    ul {
      list-style: none;
      margin: 0;

      li {
        padding: 5px 0;

        a {
          color: #fff;
        }
      }
    }
  }

  &__social {
    font-size: 30px;
    margin-top: -14px;

    @media (max-width: 756px) {
      margin-top: 0;
    }

    ul {
      display: flex;

      li {
        padding: 5px 15px;

        &:first-child {
          @media (max-width: 756px) {
            padding: 5px 0;
          }
        }
      }
    }
  }

  .color-tweeter {
    color: #38a1f3;
  }

  .color-fb {
    color: #3b5998;
  }

  .color-insta {
    color: #833ab4;
  }

  .color-link {
    color: #2867b2;
  }

  &__content {
    display: flex;
    justify-content: center;
    color: #fff;

    @media (max-width: 756px) {
      align-items: center;
      flex-direction: column;
    }
  }
}

.tg-services {
  &__cards {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;

    &-item {
      width: 50%;
      padding: 50px 20px 50px 50px;

      &--white {
        background-color: #fff;
      }

      &--grey {
        background-color: #ccc;
      }
    }

    &-description {
      margin-top: 30px;

      &--bold {
        font-weight: 700;
      }
    }

    &-list {
      list-style: none;
      margin-bottom: 30px;

      &-item {
        display: flex;
        padding: 5px 0;
      }

      &-line {
        border-top: 2px solid;
        width: 33px;
        margin: 10px;
      }
    }

    &-dots {
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 50%;
      right: 20px;

      &-item {
        width: 10px;
        margin: 3px;
        height: 10px;
        align-self: flex-end;
        background: #797575;
        border-radius: 50%;

        &-active {
          background: #000;
        }
      }
    }
  }
}

.tg-works {
  &__breadcrumb {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #000;
    color: #fff;

    .tabs {
      border: unset;
      background: none;
    }

    &-items {
      span {
        margin-right: 20px;
      }
    }
  }

  &__cards {
    display: flex;
    padding: 30px 80px;
    flex-wrap: wrap;

    @media (max-width: 767px) {
      padding: 0 10px;
    }

    &-item {
      width: 23%;
      border-radius: 10px;
      margin: 10px;

      @media (max-width: 1140px) {
        width: 45%;
      }

      @media (max-width: 700px) {
        width: 100%;
      }
    }

    &-body {
      background: #c4c4c4;
      min-height: 280px;
    }

    &-footer {
      margin-top: 10px;
      display: flex;
      flex-direction: column;

      &-title {
        font-size: 17px;
        font-weight: 700;
        color: $color_dark;
      }

      &-sub-title {
        font-size: 14px;
        font-weight: 600;
        color: $color_dark;
      }
    }

    &-img {
      object-fit: cover;
      width: 300px;
    }

    &-desc {
      margin: 10px 5px;
    }
  }
}

.tg-error {
  margin: 0;
  width: 100%;
  height: 100%;
  font-family: "Dosis", sans-serif;
  font-weight: 300;
  -webkit-user-select: none;
  /* Safari 3.1+ */
  -moz-user-select: none;
  /* Firefox 2+ */
  -ms-user-select: none;
  /* IE 10+ */
  user-select: none;
  /* Standard syntax */

  @-moz-keyframes rocket-movement {
    100% {
      -moz-transform: translate(1200px, -600px);
    }
  }

  @-webkit-keyframes rocket-movement {
    100% {
      -webkit-transform: translate(1200px, -600px);
    }
  }

  @keyframes rocket-movement {
    100% {
      transform: translate(1200px, -600px);
    }
  }

  @-moz-keyframes spin-earth {
    100% {
      -moz-transform: rotate(-360deg);
      transition: transform 20s;
    }
  }

  @-webkit-keyframes spin-earth {
    100% {
      -webkit-transform: rotate(-360deg);
      transition: transform 20s;
    }
  }

  @keyframes spin-earth {
    100% {
      -webkit-transform: rotate(-360deg);
      transform: rotate(-360deg);
      transition: transform 20s;
    }
  }

  @-moz-keyframes move-astronaut {
    100% {
      -moz-transform: translate(-160px, -160px);
    }
  }

  @-webkit-keyframes move-astronaut {
    100% {
      -webkit-transform: translate(-160px, -160px);
    }
  }

  @keyframes move-astronaut {
    100% {
      -webkit-transform: translate(-160px, -160px);
      transform: translate(-160px, -160px);
    }
  }

  @-moz-keyframes rotate-astronaut {
    100% {
      -moz-transform: rotate(-720deg);
    }
  }

  @-webkit-keyframes rotate-astronaut {
    100% {
      -webkit-transform: rotate(-720deg);
    }
  }

  @keyframes rotate-astronaut {
    100% {
      -webkit-transform: rotate(-720deg);
      transform: rotate(-720deg);
    }
  }

  @-moz-keyframes glow-star {
    40% {
      -moz-opacity: 0.3;
    }

    90%,
    100% {
      -moz-opacity: 1;
      -moz-transform: scale(1.2);
    }
  }

  @-webkit-keyframes glow-star {
    40% {
      -webkit-opacity: 0.3;
    }

    90%,
    100% {
      -webkit-opacity: 1;
      -webkit-transform: scale(1.2);
    }
  }

  @keyframes glow-star {
    40% {
      -webkit-opacity: 0.3;
      opacity: 0.3;
    }

    90%,
    100% {
      -webkit-opacity: 1;
      opacity: 1;
      -webkit-transform: scale(1.2);
      transform: scale(1.2);
      border-radius: 999999px;
    }
  }

  .spin-earth-on-hover {
    transition: ease 200s !important;
    transform: rotate(-3600deg) !important;
  }

  .bg-purple {
    background: url(http://salehriaz.com/404Page/img/bg_purple.png);
    background-repeat: repeat-x;
    background-size: cover;
    background-position: left top;
    height: 100vh;
    overflow: hidden;
  }

  .custom-navbar {
    padding-top: 15px;
  }

  .brand-logo {
    margin-left: 25px;
    margin-top: 5px;
    display: inline-block;
  }

  .btn-go-home {
    position: relative;
    z-index: 200;
    margin: 15px auto;
    width: 100px;
    padding: 10px 15px;
    border: 1px solid #ffcb39;
    border-radius: 100px;
    font-weight: 400;
    display: block;
    color: white;
    text-align: center;
    text-decoration: none;
    letter-spacing: 2px;
    font-size: 11px;
    cursor: pointer;

    -webkit-transition: all 0.3s ease-in;
    -moz-transition: all 0.3s ease-in;
    -ms-transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
  }

  .btn-go-home:hover {
    background-color: #ffcb39;
    color: #fff;
    transform: scale(1.05);
    box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.1);
  }

  .central-body {
    padding: 10% 5% 10% 5%;
    text-align: center;
  }

  .objects img {
    z-index: 90;
    pointer-events: none;
  }

  .object_rocket {
    z-index: 95;
    position: absolute;
    transform: translateX(-50px);
    top: 75%;
    pointer-events: none;
    animation: rocket-movement 200s linear infinite both running;
  }

  .object_earth {
    position: absolute;
    top: 20%;
    left: 15%;
    z-index: 90;
    /*    animation: spin-earth 100s infinite linear both;*/
  }

  .object_moon {
    position: absolute;
    top: 12%;
    left: 25%;
    /*
    transform : rotate(0deg);
    transition: transform ease-in 99999999999s;
*/
  }

  .object_astronaut {
    animation: rotate-astronaut 200s infinite linear both alternate;
  }

  .box_astronaut {
    z-index: 110 !important;
    position: absolute;
    top: 60%;
    right: 20%;
    will-change: transform;
    animation: move-astronaut 50s infinite linear both alternate;
  }

  .image-404 {
    position: relative;
    z-index: 100;
    pointer-events: none;
  }

  .stars {
    background: url(http://salehriaz.com/404Page/img/overlay_stars.svg);
    background-repeat: repeat;
    background-size: contain;
    background-position: left top;
  }

  .glowing_stars .star {
    position: absolute;
    border-radius: 100%;
    background-color: #fff;
    width: 3px;
    height: 3px;
    opacity: 0.3;
    will-change: opacity;
  }

  .glowing_stars .star:nth-child(1) {
    top: 80%;
    left: 25%;
    animation: glow-star 2s infinite ease-in-out alternate 1s;
  }

  .glowing_stars .star:nth-child(2) {
    top: 20%;
    left: 40%;
    animation: glow-star 2s infinite ease-in-out alternate 3s;
  }

  .glowing_stars .star:nth-child(3) {
    top: 25%;
    left: 25%;
    animation: glow-star 2s infinite ease-in-out alternate 5s;
  }

  .glowing_stars .star:nth-child(4) {
    top: 75%;
    left: 80%;
    animation: glow-star 2s infinite ease-in-out alternate 7s;
  }

  .glowing_stars .star:nth-child(5) {
    top: 90%;
    left: 50%;
    animation: glow-star 2s infinite ease-in-out alternate 9s;
  }

  @media only screen and (max-width: 600px) {
    .navbar-links {
      display: none;
    }

    .custom-navbar {
      text-align: center;
    }

    .brand-logo img {
      width: 120px;
    }

    .box_astronaut {
      top: 70%;
    }

    .central-body {
      padding-top: 25%;
    }
  }
}

.tg-blog {
  .triangle {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 20px 20px 0;
    border-color: transparent #1a202c transparent transparent;
  }
}

.tg-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.85);
  z-index: 2;
}
.tg-service {
  &__content {
    padding: 30px 80px;
    font-size: 16px;

    @media (max-width: 767px) {
      padding: 30px 20px;
    }
  }

  &__card {
    display: flex;
    padding: 0px 80px;
    flex-wrap: wrap;

    @media (max-width: 767px) {
      padding: 0 10px;
    }

    &-item {
      width: 23%;
      border-radius: 2px;
      margin: 10px;
      background: #c4c4c4;
      position: relative;

      .tg-overlay {
        background-color: rgba(0, 0, 0, 0.65);
      }

      img {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }

      @media (max-width: 1140px) {
        width: 45%;
      }

      @media (max-width: 700px) {
        width: 100%;
      }
    }

    &-body {
      min-height: 150px;
    }

    &-footer {
      position: relative;
      z-index: 3;
      min-height: 300px;
      justify-content: flex-end;
      display: flex;
      align-items: flex-end;
      padding-right: 5px;
      margin-bottom: 10px;

      &-title {
        line-height: 29px;
        font-size: 22px;
        font-weight: 800;
        color: $color_white;
      }
    }
  }
}

.tg-home {
  background: none;

  @media (max-width: 768px) {
    height: unset;
  }

  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.85);
    z-index: 2;
  }

  .orbit-bullets {
    position: absolute;
    top: 70%;
    left: 45%;

    @media (max-width: 700px) {
      top: 72%;
      left: 0;
    }
  }
  .orbit-figure {
    position: relative;
  }

  .tg-scrolldown {
    transform: rotate(-90deg) !important;
    position: absolute;
    right: 0px;
    bottom: 120px;
    z-index: 2;

    @media (max-width: 700px) {
      right: 15px;
      bottom: 40px;
      transform: rotate(0deg) !important;
    }

    a {
      text-transform: uppercase;
      font-weight: 600;
      letter-spacing: 1px;
      color: #ffffff;
    }
  }

  .orbit-caption {
    top: 30%;
    bottom: unset;
    text-align: center;
    background: transparent;
    color: #ffffff;
    z-index: 2;

    .header {
      font-size: 48px;
      line-height: 65px;
      font-weight: normal;

      @media (max-width: 640px) {
        font-size: 20px;
        line-height: 24px;
      }
    }

    .description {
      font-weight: 600;
      font-size: 24px;
      line-height: 33px;

      @media (max-width: 640px) {
        font-size: 13px;
        line-height: 20px;
      }
    }
  }
}
.tg-locations-contact {
  padding-left: 0;
}

.tg-quote-wrap-diff {
  font-size: 20px;
  font-weight: 600;
  color: #000000;
  list-style-type: square;
  li {
    margin-bottom: 20px;

    @media (max-width: 550px) {
      margin-bottom: unset;
    }
  }
}
.text-align-left {
  text-align: left;
}

.color-orange {
  color: $color_orange;
}
.color-blue {
  color: #3b5998;
}
.color-green {
  color: $color_green;
}

.d-none {
  display: none;
}

.align-self-center {
  align-self: center;
}

.z-index-99999 {
  z-index: 99999;
}
.text-align-center {
  text-align: center !important;
}

.tg-careerdetail-wrap-img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.tg-career-process {
  text-align: center;
  margin-top: 60px;

  .title {
    font-size: 34px;
    color: #000000;
  }

  .sub-title {
    font-size: 22px;
    color: #000000;
  }

  .icon {
    color: #111111;
    font-size: 20px;
    font-weight: 900;
  }
}

.tg-career-tab {
  margin-top: 60px;
  .tabs {
    display: flex;
    border: 0;
    justify-content: center;

    .tabs-title > a {
      color: #14141499;
      font-size: 20px;
      font-weight: 600;
    }

    .tabs-title > a[aria-selected="true"] {
      color: #111111;
    }
  }
}
