::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-size : 14px;
  color     : #ccc;
}

::-moz-placeholder {
  /* Firefox 19+ */
  font-size : 14px;
  color     : #ccc;
}

:-ms-input-placeholder {
  /* IE 10+ */
  font-size : 14px;
  color     : #ccc;
}

:-moz-placeholder {
  /* Firefox 18- */
  font-size : 14px;
  color     : #ccc;
}

.form-group {
  margin: 0 0 1rem;
}


.form-control {
  box-shadow: none;
  height    : 45px;
  font-size : 14px;
  width     : 100%;
  margin    : 0;
}

input.form-control {
  padding: 0 10px;
}

textarea.form-control {
  height: 60px;
  resize: none;
}

.tg-formerror {
  position: relative;

  .tg-errormessage {
    position   : absolute;
    bottom     : -5px;
    color      : #ff0000;
    font-weight: 600;
    font-size  : 12px;
  }

  .form-control {
    border-color: #ff0000;
  }
}

.form-control.error {
  border: 1px solid #ff0000 !important;
}

label.error {
  color      : #ff0000 !important;
  font-weight: 600;
  font-size  : 12px;
}

.pv-attachmentInput {
  display: none;
}

#attachmentInputFile,
.pv-google-recaptcha-input {
  opacity : 0;
  position: absolute;
  top     : 0;
  left    : 0;
  height  : 1px;
  width   : 1px;
}

.tg-custom-error {
  color      : #ff0000;
  font-weight: 600;
  font-size  : 12px;
}