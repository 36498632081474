h1,
h2,
h3,
h4 {
  font-family: $prata;
}

h1 {
  font-size: 48px;

  @include breakpoint(small only) {
    font-size: 35px;
  }
}

h2,
h3 {
  font-size: 42px;

  @include breakpoint(small only) {
    font-size: 28px;
  }
}

h6 {
  font-family   : $open_sans;
  font-size     : 17px;
  font-weight   : 700;
  letter-spacing: 2px;
}

p {
  line-height: 23px;
}

.mt-130 {
  @include breakpoint(small only) {
    margin-top: 60px !important;
  }
}

.tg-title {
  text-align: center;

  h2,
  h3 {
    margin-bottom: 20px;
  }

  h6 {
    font-size     : 17px;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-bottom : 25px;
    color         : $color_dark;

    @include breakpoint(small only) {
      font-size: 15px;
    }
  }
}

.tg-title-2 {
  text-align: center;
  padding   : 0 15px;
  width     : 700px;
  margin    : 0 auto;
  max-width : 100%;
  color     : $color_dark;

  .tg-subtitle {
    display       : block;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight   : 600;
    color         : $color_black;
    margin-bottom : 25px;
  }

  h2,
  h3 {
    margin-bottom: 20px;
    color        : $color_black;
  }

  p {
    font-size: 17px;

    @include breakpoint(small only) {
      font-size: 15px;
    }
  }
}

.tg-emaillink {
  color  : $color_black;
  display: inline-block;

  &:hover {
    text-decoration: none;
  }
}