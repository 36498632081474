.tg-admin {
    .tg-user-setting {
        &-header {
            margin-top: 10px;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .icon {
                width: 30px;
                height: 30px;
                border: 1px solid;
                margin-left: 20px;
                border-radius: 50%;
                margin-top: -13px;
                cursor: pointer;
                text-align: center;

                i {
                    vertical-align: sub; 
                }
            }
        }

        table {
            .action {
                text-align: center;

                .icon {
                    width: 26px;
                    display: inline-block;
                    height: 30px;
                    background: #3c3c3c;
                    border-radius: 50%;
                    color: #fff;

                    i {
                        vertical-align: sub;
                    }
                }
            }
        }
    }
    
    .tg-add-user {
        
    }
}
