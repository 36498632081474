.slick-prev:before,
.slick-next:before {
    font-family: "Icofont";
    color: $color_black;
    font-size: 28px;
    line-height: 31px;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
    opacity: 1;
}

.slick-prev.slick-disabled,
.slick-next.slick-disabled {
    opacity: 0.2;
    cursor: no-drop;
}

.slick-prev:before {
    content: "\ea93";
}

.slick-next:before {
    content: "\ea94";
}

.slick-arrow {
    background: $color_white;
    border: 2px solid $color_black;
    width: 40px;
    height: 40px;
    z-index: 999;
}

.slick-next {
    right: 0;
}

.slick-prev {
    left: 0;
}

.bg-grey {
    background: #f2f2f2;
}

.bg-dark-grey {
    background: #e4e4e4;
}

// HOME SERVICE SLIDER
.tg-serviceslider {
    overflow: hidden;

    .item {
        height: 480px;
        padding: 85px 45px;
        position: relative;
        overflow: hidden;

        .overlay {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.85);
            z-index: 2;
        }

        img {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            right: 0;
            z-index: 1;
            object-fit: cover;
        }

        .pv-slidercontent {
            position: relative;
            z-index: 2;
        }

        .tg-slidertitle {
            span {
                display: block;
                letter-spacing: 2px;
                font-size: 20px;
                font-weight: 700;
                color: $color_white;

                &:first-child {
                    margin-bottom: 10px;
                }
            }
        }

        .tg-caption {
            margin-top: 35px;

            p {
                margin: 0;
                color: $color_white;
                font-size: 17px;
            }
        }

        .btn-explore {
            position: absolute;
            left: 45px;
            bottom: 45px;
            z-index: 5;
            border: 2px solid $color_white;
            i {
                color: #ffffff;
            }
        }
    }
}

.slick-dots {
    position: relative;
    bottom: auto;

    li {
        width: auto;
        height: auto;

        button {
            background: #bfbfbf;
            height: 6px;
            width: 25px;
            padding: 0;
            @include border-radius(60px);
            @include transition(all 0.3s ease 0s);

            &:before {
                display: none;
            }
        }

        &.slick-active {
            button {
                background: $color_blue;
                width: 50px;
            }
        }
    }
}

//TESTIMONIALS SLIDER
.tg-testi-slider {
    .tg-client-content {
        margin-top: 30px;

        .tg-client-img {
            width: 85px;
            height: 85px;
            margin: 0 auto;
            @include border-radius(100%);
            overflow: hidden;

            img {
                object-fit: cover;
            }
        }

        .tg-client-name {
            margin-top: 15px;

            h6 {
                letter-spacing: 0;
                font-size: 22px;
                margin-bottom: 0;
            }

            span {
                display: block;
            }
        }
    }
}
