html,
body{
    overflow-x: hidden;
}
body{
    font-family: $open_sans;
    font-size: 15px;
    color: $color_black;
    line-height: 21px;
}

.clearfix:before,
.clearfix:after {
    content: " "; /* 1 */
    display: table; /* 2 */
}