.tg-navarrow {
  width      : 40px;
  height     : 40px;
  border     : 1px solid transparent;
  text-align : center;
  display    : block;
  text-align : center;
  line-height: 36px;
  font-size  : 28px;
  position   : relative;

  &:before,
  &:after {
    content         : "";
    width           : 0;
    height          : 1px;
    position        : absolute;
    transition      : all 0.2s linear;
    background      : $color_black;
    transition-delay: 0.2s;
  }

  &:before {
    right: 0;
    top  : 0;
  }

  &:after {
    left  : 0;
    bottom: 0;
  }

  span {
    display: block;

    &:before,
    &:after {
      content         : "";
      width           : 1px;
      height          : 0;
      position        : absolute;
      transition      : all 0.2s linear;
      background      : $color_black;
      transition-delay: 0s;
    }

    &:before {
      left: 0;
      top : 0;
    }

    &:after {
      right : 0;
      bottom: 0;
    }
  }

  &:hover {

    &:before,
    &:after {
      width           : 100%;
      transition-delay: 0s;
    }

    span {

      &:before,
      &:after {
        height          : 100%;
        transition-delay: 0.2s;
      }
    }
  }
}

.tg-textnavarrow {
  color      : $color_black;
  font-weight: 600;
  @include transition(all 0.3s ease 0s);

  span {
    display : inline-block;
    position: relative;

    &:before {
      content   : "";
      width     : 0;
      height    : 2px;
      background: $color_blue;
      position  : absolute;
      left      : 0;
      bottom    : -5px;
      @include transition(all 0.3s ease 0s);
    }
  }

  i {
    font-size     : 28px;
    vertical-align: middle;
    margin-left   : 0px;
    @include transition(all 0.3s ease 0s);
  }

  &:hover {
    color: $color_blue;

    span {
      &:before {
        width: 100%;
      }
    }

    i {
      margin-left: 10px;
    }
  }
}

.btn-explore {
  display : block;
  width   : 50px;
  height  : 50px;
  border  : 2px solid $color_dark;
  color   : $color_white;
  position: relative;
  overflow: hidden;
  @include transition(all 0.3s ease 0s);

  &:focus {
    outline: none;
  }

  span {
    opacity       : 0;
    position      : absolute;
    top           : 11px;
    left          : -15px;
    font-size     : 15px;
    font-weight   : 600;
    color         : $color_white;
    letter-spacing: 1px;
    @include transition(all 0.3s ease 0s);
  }

  i {
    font-size: 34px;
    color    : $color_dark;
    position : absolute;
    top      : 7px;
    right    : 6px;
  }

  &:hover {
    width     : 150px;
    background: $color_dark;

    span {
      opacity: 1;
      left   : 15px;
    }

    i {
      color: $color_white;
    }
  }
}

.btn-orange {
  background: $color_orange;
  position  : relative;
  overflow  : hidden;
  border    : none;
  padding   : 15px 15px;

  &:before {
    content : "";
    position: absolute;
    width   : 52px;
    height  : 206px;
    top     : -38px;
    left    : -100px;
    @include transition(all 0.5s ease 0s);
    @include transform(rotate(#{45}deg));
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0.4+0,0+100 */
    background: -moz-linear-gradient(left, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(left,
        rgba(255, 255, 255, 0.4) 0%,
        rgba(255, 255, 255, 0) 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right,
        rgba(255, 255, 255, 0.4) 0%,
        rgba(255, 255, 255, 0) 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#66ffffff', endColorstr='#00ffffff', GradientType=1);
    /* IE6-9 */
  }

  &:hover {
    background: darken($color_orange, 1%);

    &:before {
      left: 110%;
    }
  }
}

.btn-black {
  background: $color_dark;
  position  : relative;
  overflow  : hidden;
  border    : none;
  padding   : 15px 15px;

  &:before {
    content : "";
    position: absolute;
    width   : 52px;
    height  : 206px;
    top     : -38px;
    left    : -100px;
    @include transition(all 0.5s ease 0s);
    @include transform(rotate(#{45}deg));
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0.4+0,0+100 */
    background: -moz-linear-gradient(left, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(left,
        rgba(255, 255, 255, 0.4) 0%,
        rgba(255, 255, 255, 0) 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right,
        rgba(255, 255, 255, 0.4) 0%,
        rgba(255, 255, 255, 0) 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#66ffffff', endColorstr='#00ffffff', GradientType=1);
    /* IE6-9 */
  }

  &:hover {
    background: darken($color_dark, 1%);

    &:before {
      left: 110%;
    }
  }
}